import '../../styles/index.scss';
import '../../styles/float-labels.scss';
window.onload  = () => {
    // Definitions
    const loginFormSubmitId = 'userNameFormSubmit';
    const userInputId = 'userInput';
    const inputEvents = ["click", "blur", "change", "keyup", "input", "propertychange", "paste"];
    // Enables the submit button when username is not empty
    const enableSubmitBtn = () => {
        let submitBtn = document.getElementById(loginFormSubmitId);
        let userInput = document.getElementById(userInputId);
        let validateInput = () => {
            submitBtn.disabled = !(userInput.value);
        };
        inputEvents.forEach((event) => {
            window.addEventListener(event, validateInput, false);
        });
        validateInput();
    }
    // Initialization
    enableSubmitBtn();
    document.getElementById(userInputId).select();
    // Disable the submit button from being clicked multiple times
    document.addEventListener('submit', function(){
        let submitButton = document.getElementById(loginFormSubmitId);
        submitButton.disabled = true;
    });
};
